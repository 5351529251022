import { envConfig } from 'env/config';

export const FEATURE_FLAGS = {
  mobile: {
    showNewCustomerDiscountPopup: isFeatureEnabled(
      envConfig.NEXT_PUBLIC_FEATURES_MOBILE_NEW_CUSTOMER_DISCOUNT_POPUP,
    ),
  },
  cache: {
    loop: isFeatureEnabled(envConfig.NEXT_PUBLIC_FEATURES_CACHE_LOOP),
  },
  explodeVariants: {
    search: isFeatureEnabled(
      envConfig.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH,
    ),
    products: isFeatureEnabled(
      envConfig.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS,
    ),
  },
  checkout: {
    walley: isFeatureEnabled(envConfig.NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY),
    madeToOrderNotification: isFeatureEnabled(
      envConfig.NEXT_PUBLIC_FEATURES_CHECKOUT_MADE_TO_ORDER,
    ),
  },
  datadog: {
    rum: isFeatureEnabled(envConfig.NEXT_PUBLIC_DATADOG_RUM),
  },
} as const;

function isFeatureEnabled(value?: string) {
  const compare = value?.localeCompare('true', undefined, {
    sensitivity: 'accent',
  });

  return compare === 0;
}
