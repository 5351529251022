'use client';

import React, { useEffect } from 'react';
import QueryContext from '@ui/context/queryContext';
import { datadogRum } from '@datadog/browser-rum';
import { envConfig } from '@env/config';
import { FEATURE_FLAGS } from '@config/featureFlags';
import { usePathname } from 'next/navigation';
type Props = {
  children: React.ReactNode;
};

const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|Screaming Frog SEO Spider|HeadlessChrome)';

const regex = new RegExp(botPattern, 'i');

// define var conditionalSampleRate as 0 if the userAgent matches a pattern in botPatterns
// otherwise, define conditionalSampleRate as 100
if (FEATURE_FLAGS.datadog.rum) {
  if (typeof window !== 'undefined') {
    // fall back to 10%
    const fallbackSampleRate = 10;
    let parsedMaxConditionalSampleRate = parseInt(
      envConfig.NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
    );
    // unless an integer and completely in range (0 to 100 inclusive), use fallback
    if (
      isNaN(parsedMaxConditionalSampleRate) ||
      !isFinite(parsedMaxConditionalSampleRate) ||
      parsedMaxConditionalSampleRate < 0 ||
      parsedMaxConditionalSampleRate > 100
    ) {
      parsedMaxConditionalSampleRate = fallbackSampleRate;
    }

    const conditionalSampleRate = regex.test(navigator.userAgent)
      ? 0
      : parsedMaxConditionalSampleRate;

    if (
      envConfig.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
      envConfig.NEXT_PUBLIC_DATADOG_APPLICATION_ID
    ) {
      datadogRum.init({
        applicationId: envConfig.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: envConfig.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: envConfig.NEXT_PUBLIC_DATADOG_SITE,
        env: envConfig.NEXT_PUBLIC_DATADOG_ENV,
        version: envConfig.NEXT_PUBLIC_APP_VERSION,
        service: envConfig.NEXT_PUBLIC_DATADOG_SERVICE,
        allowedTracingUrls: [
          (url) => url.startsWith(envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT),
        ],
        sessionSampleRate: conditionalSampleRate,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
    // datadogRum.startSessionReplayRecording();
  }
}

export default function AppContextProvider({ children }: Props) {
  const pathname = usePathname();
  useEffect(() => {
    const currentPath = sessionStorage.getItem('currentPath')
    if (currentPath !== null) {
      sessionStorage.setItem('previousPath', currentPath);
    }
    sessionStorage.setItem('currentPath', pathname);
    () => sessionStorage.clear();
  }, [pathname])
  return <QueryContext>{children}</QueryContext>;
}
